import React from "react";

const Operational = () => {
  return (
    <div>
      <h1>RMM</h1>
      <iframe
        title="RMM"
        width="100%"
        height="450px"
        src="https://wwweurope1.systemmonitor.eu.com/dashboard/wallchart"
      />
    </div>
  );
};

export default Operational;
