import { Card } from "antd";
import React from "react";

const MainApps = () => {
  return (
    <Card title="Main Applications">
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a href="https://www.ovh.com/manager" target="_blank" rel="noreferrer">
          <img
            height="55px"
            src="https://pic.clubic.com/v1/images/1840482/raw"
            alt="noalt"
          />
          <br />
          <br />
          OVH
        </a>
      </Card.Grid>
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a href="https://www.ahead-it.eu/en" target="_blank" rel="noreferrer">
          <img
            height="40px"
            src="https://www.ahead-it.eu/site/data/images/skin/default/ahead-it_logo.svg"
            alt="noalt"
          />
          <br />
          <br />
          Ahead IT
        </a>
      </Card.Grid>
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a href="https://be.cloud.im/" target="_blank" rel="noreferrer">
          <img
            height="30px"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Ingram_Micro_logo_new.svg/1280px-Ingram_Micro_logo_new.svg.png"
            alt="noalt"
          />
          <br />
          <br />
          Ingram Cloud
        </a>
      </Card.Grid>
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a href="https://be.ingrammicro.com" target="_blank" rel="noreferrer">
          <img
            height="30px"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Ingram_Micro_logo_new.svg/1280px-Ingram_Micro_logo_new.svg.png"
            alt="noalt"
          />
          <br />
          <br />
          Ingram Micro
        </a>
      </Card.Grid>
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a
          href="https://intouch.techdata.com/InTouch"
          target="_blank"
          rel="noreferrer"
        >
          <img
            height="55px"
            src="https://wvmetronews.com/wp-content/uploads/2020/05/tech-data-logo.png"
            alt="noalt"
          />
          <br />
          <br />
          TechData Intouch
        </a>
      </Card.Grid>
    </Card>
  );
};

export default MainApps;
