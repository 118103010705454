import { Card } from "antd";
import React from "react";

const MainApps = () => {
  return (
    <Card title="Main Applications">
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a
          href="https://app.teamleader.eu/dashboard.php?login"
          target="_blank"
          rel="noreferrer"
        >
          <img
            height="55px"
            src="https://pbs.twimg.com/profile_images/1278229877339099136/yiYZzO30_400x400.png"
            alt="noalt"
          />
          <br />
          <br />
          CRM
        </a>
      </Card.Grid>
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a
          href="https://4eyesit.sharepoint.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            height="55px"
            src="https://www.dox42.com/media/1552bd8c-2adf-4901-ba84-9f9d4c15e1a3/dCZ2AQ/Pics/Icons/SharePoint2019.png"
            alt="noalt"
          />
          <br />
          <br />
          Intranet - Home
        </a>
      </Card.Grid>
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a
          href="https://4eyesit.sharepoint.com/sites/clients"
          target="_blank"
          rel="noreferrer"
        >
          <img
            height="55px"
            src="https://www.dox42.com/media/1552bd8c-2adf-4901-ba84-9f9d4c15e1a3/dCZ2AQ/Pics/Icons/SharePoint2019.png"
            alt="noalt"
          />
          <br />
          <br />
          Intranet - Clients
        </a>
      </Card.Grid>
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a href="https://outlook.office.com/" target="_blank" rel="noreferrer">
          <img
            height="55px"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg/1200px-Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg.png"
            alt="noalt"
          />
          <br />
          <br />
          Mail
        </a>
      </Card.Grid>
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a href="https://portal.office.com/" target="_blank" rel="noreferrer">
          <img
            height="55px"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Microsoft_Office_logo_%282019%E2%80%93present%29.svg/1200px-Microsoft_Office_logo_%282019%E2%80%93present%29.svg.png"
            alt="noalt"
          />
          <br />
          <br />
          Office365 Portal
        </a>
      </Card.Grid>
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a href="https://teams.microsoft.com/" target="_blank" rel="noreferrer">
          <img
            height="55px"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg/1200px-Microsoft_Office_Teams_%282018%E2%80%93present%29.svg.png"
            alt="noalt"
          />
          <br />
          <br />
          Teams
        </a>
      </Card.Grid>
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a
          href="https://partner.microsoft.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            height="55px"
            src="https://pngimg.com/uploads/microsoft/microsoft_PNG13.png"
            alt="noalt"
          />
          <br />
          <br />
          MS Partner Center
        </a>
      </Card.Grid>
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a
          href="https://monitoring.4eyes.be:8080/index.htm"
          target="_blank"
          rel="noreferrer"
        >
          <img
            height="55px"
            src="https://www.clipartmax.com/png/full/142-1426702_prtg-network-monitor-logo.png"
            alt="noalt"
          />
          <br />
          <br />4 Eyes Monitoring (OLD)
        </a>
      </Card.Grid>
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a
          href="https://wwweurope1.systemmonitor.eu.com/dashboard/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            height="55px"
            src="https://media.trustradius.com/product-logos/qE/u1/0KQHC3P7NGDW-180x180.PNG"
            alt="noalt"
          />
          <br />
          <br />
          Remote Montitoring & Management
        </a>
      </Card.Grid>
      <Card.Grid style={{ width: "217px", textAlign: "center" }}>
        <a href="https://app.mspmanager.com/" target="_blank" rel="noreferrer">
          <img
            height="55px"
            src="https://media.trustradius.com/product-logos/qE/u1/0KQHC3P7NGDW-180x180.PNG"
            alt="noalt"
          />
          <br />
          <br />
          MSP Manager
        </a>
      </Card.Grid>
    </Card>
  );
};

export default MainApps;
