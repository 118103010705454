import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useAccount,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./auth/authConfig";

import Layout from "./components/layout";

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest);
  }

  return (
    <div className="App">
      <AuthenticatedTemplate>
        <AuthenticatedContent />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div>Signing in...</div>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default App;

const AuthenticatedContent = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  console.log("AuthenticatedContent() - account", account);

  return (
    <>
      {account ? (
        <Layout loggedInAccount={account} />
      ) : (
        <div>Not logged in correctly!</div>
      )}
    </>
  );
};
