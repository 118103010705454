import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { AccountInfo } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";
import {
  Row,
  Col,
  Layout as AntdLayout,
  Menu,
  Breadcrumb,
  Button,
  Dropdown,
  notification,
  Badge,
  Space,
} from "antd";
import styled from "styled-components";
import {
  AppstoreOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  SmileOutlined,
  RocketOutlined,
  ShoppingCartOutlined,
  NotificationOutlined,
} from "@ant-design/icons";

import "antd/dist/antd.css";
import logo from "../assets/logo-white.png";
import { GetInitials, TrimUsername } from "../helpers/helpers";
import MainApps from "./MainApps";
import Dashboard from "./Dashboard";
import Operational from "./Operational";
import Providers from "./Providers";

const { Header, Content, Footer, Sider } = AntdLayout;
const { SubMenu } = Menu;

interface LayoutProps {
  loggedInAccount: AccountInfo;
}

const openNotification = () => {
  notification.open({
    message: "Welcome!",
    description:
      "Welcome on the portal of 4Eyes. This portal is still under construction and in an experimental state. Please be aware that some problems/errors may still occur, feel free to report this to us :)",
    icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    placement: "bottomRight",
  });
};

const Layout = (props: LayoutProps) => {
  const [collapsed, setCollapsed] = useState(false);

  function toggle() {
    setCollapsed(!collapsed);
  }

  useEffect(() => {
    openNotification();
  }, []);
  return (
    <Router>
      <AntdLayout style={{ minHeight: "100vh" }}>
        <Sider collapsible collapsed={collapsed} onCollapse={toggle}>
          <StyledLogo src={logo} alt="4Eyes Logo" />
          <MainMenu />
        </Sider>
        <AntdLayout className="site-layout">
          <Header
            className="site-layout-background"
            style={{ padding: 0, backgroundColor: "#fff" }}
          >
            <Row>
              <Col
                offset={12}
                span={12}
                style={{ textAlign: "right", paddingRight: 20 }}
              >
                <HeaderActions loggedInAccount={props.loggedInAccount} />
              </Col>
            </Row>
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>4Eyes Portal</Breadcrumb.Item>
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            </Breadcrumb>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 360, backgroundColor: "#fff" }}
            >
              <Route exact path="/" component={Dashboard} />
              <Route path="/mainapps" component={MainApps} />
              <Route path="/operational" component={Operational} />
              <Route path="/providers" component={Providers} />
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            4Eyes Company Portal ©2021 Created by 4Eyes
            <span style={{ float: "right" }}>v0.2.4</span>
          </Footer>
        </AntdLayout>
      </AntdLayout>
    </Router>
  );
};

export default Layout;

const MainMenu = () => {
  return (
    <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
      <Menu.Item key="1" icon={<PieChartOutlined />}>
        Dashboard
        <Link to="/" />
      </Menu.Item>
      <Menu.Item key="2" icon={<AppstoreOutlined />}>
        Main Apps
        <Link to="/mainapps" />
      </Menu.Item>
      <Menu.Item key="3" icon={<RocketOutlined />}>
        Operational
        <Link to="/operational" />
      </Menu.Item>
      <Menu.Item key="4" icon={<ShoppingCartOutlined />}>
        Providers
        <Link to="/providers" />
      </Menu.Item>
      <Menu.Item key="5" icon={<UserOutlined />}>
        Users
      </Menu.Item>
      <SubMenu key="sub5" icon={<TeamOutlined />} title="Clients">
        <Menu.Item key="6">1</Menu.Item>
        <Menu.Item key="7">2</Menu.Item>
      </SubMenu>
      <Menu.Item key="8" icon={<FileOutlined />}>
        Files
      </Menu.Item>
    </Menu>
  );
};

const HeaderActions = (userProps: LayoutProps) => {
  const { instance } = useMsal();
  const trimmedname = TrimUsername(userProps.loggedInAccount.name);
  const initials = GetInitials(trimmedname);

  const UserMenu = (
    <Menu>
      <Menu.Item disabled>Profile</Menu.Item>
      <Menu.Item disabled>Settings</Menu.Item>
      <Menu.Item
        danger
        onClick={() => handleLogout("redirect")}
        key="logoutRedirect"
      >
        Log out
      </Menu.Item>
    </Menu>
  );
  const handleLogout = (logoutType: string) => {
    if (logoutType === "popup") {
      instance.logoutPopup();
    } else if (logoutType === "redirect") {
      instance.logoutRedirect();
    }
  };
  return (
    <Space>
      Welcome {userProps.loggedInAccount ? trimmedname : "unknown"}
      <Badge count={99}>
        <NotificationOutlined style={{ fontSize: "20px" }} />
      </Badge>
      <Dropdown overlay={UserMenu} overlayStyle={{ minWidth: 150 }}>
        <Button
          type="primary"
          shape="circle"
          size="large"
          style={{ marginLeft: 10, background: "#005564", border: "#005564" }}
        >
          {initials}
        </Button>
      </Dropdown>
    </Space>
  );
};

const StyledLogo = styled.img`
  height: 32px;
  margin: 14px;
`;
