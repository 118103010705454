import React from "react";
import { Statistic, Card, Col, Row } from "antd";
import {
  TeamOutlined,
  FileExclamationOutlined,
  FileAddOutlined,
  LineChartOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
const Dashboard = () => {
  return (
    <div>
      <Row>
        <Col span={6} style={{ textAlign: "right", padding: 20 }}>
          <Card style={{ background: "#264653" }}>
            <Statistic
              title={<StyledStatTitle>Total Active Clients</StyledStatTitle>}
              value={28}
              valueStyle={{ color: "white" }}
              prefix={<TeamOutlined />}
            />
          </Card>
        </Col>
        <Col span={6} style={{ textAlign: "right", padding: 20 }}>
          <Card style={{ background: "#e76f51" }}>
            <Statistic
              title={<StyledStatTitle>Overdue Invoices</StyledStatTitle>}
              value={2}
              valueStyle={{ color: "white" }}
              prefix={<FileExclamationOutlined />}
            />
          </Card>
        </Col>
        <Col span={6} style={{ textAlign: "right", padding: 20 }}>
          <Card style={{ background: "#e9c46a" }}>
            <Statistic
              title={<StyledStatTitle>Pending Invoices</StyledStatTitle>}
              value={2}
              valueStyle={{ color: "white" }}
              prefix={<FileAddOutlined />}
            />
          </Card>
        </Col>
        <Col span={6} style={{ textAlign: "right", padding: 20 }}>
          <Card style={{ background: "#f4a261" }}>
            <Statistic
              title={<StyledStatTitle>Ongoing Deals</StyledStatTitle>}
              value={4}
              valueStyle={{ color: "white" }}
              prefix={<LineChartOutlined />}
            />
          </Card>
        </Col>
        <Col span={6} style={{ textAlign: "right", padding: 20 }}>
          <Card style={{ background: "#2a9d8f" }}>
            <Statistic
              title={<StyledStatTitle>Open Tickets</StyledStatTitle>}
              value={4}
              valueStyle={{ color: "white" }}
              prefix={<ExclamationCircleOutlined />}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;

const StyledStatTitle = styled.p`
  color: #fff;
  font-size: 18px;
`;
